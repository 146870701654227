import React, { memo } from 'react'
import ReactPlayer from 'react-player/lazy'
import { Box, BoxProps, styled } from '@mui/material'
import Caption from '@atoms/text/Caption'

interface YouTubeVideoProps extends BoxProps {
  youTubeUrl: string
  caption: string
}

const PlayerWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  height: 0,
  paddingTop: '56.25% !important',
}))

const StyledReactPlayer = styled(ReactPlayer)({
  position: 'absolute',
  top: 0,
  left: 0,
})

const ContentYouTubeVideo = ({
  youTubeUrl,
  caption,
  ...props
}: YouTubeVideoProps) => {
  return (
    <>
      <PlayerWrapper>
        <StyledReactPlayer
          light
          playing
          controls
          url={youTubeUrl}
          width="100%"
          height="100%"
        />
      </PlayerWrapper>
      <Caption mt={3} text={caption} />
    </>
  )
}

export default memo(ContentYouTubeVideo)
