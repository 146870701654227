import React, { memo } from 'react'
import { Box, BoxProps, styled } from '@mui/material'

export interface CaptionProps extends BoxProps {
  text: string
}

const StyledBox = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  paddingLeft: '10px',
  marginTop: '20px',
  lineHeight: 1,
}))

const Caption = ({ text, ...props }: CaptionProps) => {
  return <StyledBox {...props}>{text}</StyledBox>
}

export default memo(Caption)
